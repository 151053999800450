<template>
    <Dialog ref="dialog" :config="config" :visible='visible' :cancelDialog="cancelDialog" :confirmDialog='submitGroupForm' :isdeling='isdeling' append>
        <div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="131px" class="demo-ruleForm">
           <el-form-item label="续费年限(1-10年)" prop="years" >
              <el-input-number v-model="ruleForm.years" controls-position="right" :min="1" :max="10" class="filter-item"></el-input-number>
           </el-form-item>
           <el-form-item label="经办人姓名" prop="operatorName">
              <el-input v-model="ruleForm.operatorName"></el-input>
           </el-form-item>
           <el-form-item label="经办人手机号" prop="operatorPhone">
              <el-input v-model="ruleForm.operatorPhone"></el-input>
           </el-form-item>
          </el-form>
        </div>
  </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog/index";
import { regionData ,CodeToText } from 'element-china-area-data'
export default {
  props: {
    type:{
      type: String,
    },
    rowData :{
      type: Object,
    },
    visible: {
      type: Boolean,
    },
    isdeling: {
      type: Boolean,
    },
    cancelDialog:{
      type: Function,
      default: () => {},
    },
    confirmDialog:{
      type: Function,
      default: () => {},
    }
  },
  components: {
    Dialog,
  },
  data() {
    const checkPhone = (rule, value, callback) => {
      if(value) {
        var pass=false;
        const mobile = /^(((\+86)|(\+886)|(\+852)|(\+853))\s+)?((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|190)\d{8}$/;
        if(mobile.test(value)){
          pass=true;
        }
        if (!pass) {
          return callback(
              new Error("请输入正确的手机号码")
          )
        }
      }
      callback()
    }
    return {
      addressOptions: regionData,  //地区
      config: {
        top: '14vh',
        width: '22rem',
        title: '填写续费信息',
        center: true,
        btnTxt: ['保存','取消'],
      },
      ruleForm: {
          years:'',
          operatorName: '',
          operatorPhone:'',
        },
      rules: {
          years: [
            { required: true, message: '请输入续费年限', trigger: 'blur' },
          ],
          operatorName: [
            { required: true, message: '请输入经办人姓名', trigger: 'blur' },
          ],
          operatorPhone: [{ required: true, message: "请输入手机号码" },{ validator: checkPhone, trigger: 'blur' }],
         
        }
    };
  },
  methods: {
      submitGroupForm(){
        console.log(this.$refs,'this.$refs')
        let data = { ...this.ruleForm,companyId:this.rowData.companyId,id:this.rowData.id};
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
           this.confirmDialog(data) 
          }
        })
      },
  }
};
</script>

<style lang="less" scoped>
  .groupForm{
    width: 100%;
    margin: 0 auto;
    .submitGroupBtn{
      margin: 20px auto 0;
      display: block;
    }
  }
  /deep/ .el-form--label-top .el-form-item__label{
    padding: 0;
  }
  /deep/ .el-form-item {
    // margin-bottom: 0.7rem;
  }
  .filter-item{
  width: 100%;
}
/deep/ .el-input-number .el-input__inner {
  text-align: left; //位置居左
}

</style>
